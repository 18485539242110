$black: #212121;
$grey: #727272;
$lightgrey: #bababa;
$white: #fff;

$orange: #fe512c;
$blue: #0094c6;
$lightblue: #dbfcff;
$purple: #3b429f;
$green: #6CE366;
$lightgreen: #bfffbc;
$red: #E71D36;

$mc: $orange;
$sc: $blue;

$link: $sc;
$tc: $grey;

$radius: 18px;
$radius-lg: 30px;
$radius-sm: 14px;
$radius-ex-sm: 12px;

$box-shadow: 0 6px 20px -1px rgba($black, 0.1);
$box-shadow-active: 0 7px 20px -1px rgba($black, 0.15);
$box-shadow-lighter: 0 6px 20px -1px rgba($black, 0.06);
$box-shadow-big-item: 0 0 60px 0px rgba($black, 0.08);

$font: 'Poppins', sans-serif;