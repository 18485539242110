.rv-xy-plot__series--bar rect,
.bubble-wrap circle,
.horiz-histo-wrap li,
.total-table-wrap li {
	transition: opacity 0.2s ease;
	cursor: pointer;

	&.hovered {
		opacity: 1;
	}

	&.not-hovered {
		opacity: 0.1 !important;
	}
}

.horiz-histo-wrap {
	margin: 20px;

	li {
		width: 100%;
		margin-bottom: 16px;
		line-height: 1.4;

		> span {
			display: flex;
			justify-content: space-between;
		}

		.histo-label {
			font-size: 14px;
			margin-bottom: 4px;
			pointer-events: none;

			*:first-child {
				color: $grey;
				font-weight: 500;
			}

			*:last-child {
				font-size: 16px;
				font-weight: 700;
			}
		}

		.histo-bar {
			position: relative;
			display: block;
			width: 100%;
			height: 8px;
			background: rgba($lightgrey, 0.1);
			pointer-events: none;

			&-fill {
				display: block;
				width: 0;
				height: 100%;
				transition: width 0.6s ease;
				background: $mc;
				pointer-events: none;
			}
		}
	}
}

.total-table-wrap {
	margin: 20px;

	li {
		width: 100%;
		margin-bottom: 8px;
		line-height: 1.4;

		> span {
			display: flex;
			justify-content: space-between;
		}

		.table-label {
			font-size: 14px;
			margin-bottom: 4px;

			*:first-child {
				color: $grey;
				font-weight: 500;
			}

			*:last-child {
				font-size: 16px;
				font-weight: 500;
			}
		}

		&:last-of-type .table-label * {
			font-weight: 900;
		}
	}
}
