.pie-chart-wrap {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 10px;

	.container {
		width: 55%;
	}

	.rv-discrete-color-legend {
		position: initial !important;
		top: initial !important;
		right: initial !important;
		transform: initial !important;
		margin: 0 20px 10px;
	}
}
