.page-header {
    margin-bottom: 20px;
	
	
	.upper-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 30px;

		.count-wrap {
			max-width: 240px;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
	
			> b {
				color: $mc;
				font-size: 32px;
				font-weight: 700;
				margin-right: 8px;
				white-space: nowrap;
			}
	
			span {
				color: $sc;
				font-size: 12px;
				font-weight: 500;
				margin-top: -4px;
	
				b {
					font-weight: 700;
				}
			}
		}

        .right-part {
            display: flex;
            align-items: center;

            .user-actions {
                cursor: pointer;
                transition: all 0.2s ease;
                padding: 8px 12px;
                margin-left: 24px;
                margin-right: -12px;
                border-radius: 12px;
    
                &:hover {
                    background: rgba($lightgrey, 0.05);
                }
    
                .menu-btn,
                .login-btn {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
    
                .login-btn {
                    font-size: 14px;
                    font-weight: 700;
                    height: 32px;
                    padding: 0 8px 0 4px;
    
                    @include icon {
                        font-size: 16px;
                        margin-right: 20px;
                    }
                }
    
                .menu-btn {
                    .ant-avatar {
                        color: $mc;
                        font-weight: 900;
                        font-size: 12px;
                        line-height: 26px;
						border: 3px solid rgba($grey, 0.2);
                        box-shadow: 0 0 8px 0 rgba($grey, 0.15);

						&:not(.ant-avatar-image) {
	                        background: rgba($mc, 0.2);
							border-color: $mc;
							box-shadow:  0 0 8px 0 rgba($mc, 0.25);
						}
                    }
        
                    .user-descr {
                        font-size: 14px;
                        font-weight: 700;
                        margin-left: 12px;
        
                        small {
                            font-size: 10px;
                            color: $lightgrey;
                            display: block;
                            margin-top: 2px;
                        }
                    }
                    
                    @include icon {
                        font-size: 16px;
                        margin-left: 20px;
                    }
                }
            }
        }

		@media only screen and (max-width: 1200px) {
			align-items: flex-start;

			.count-wrap {
				margin-top: 5px;
			}

			.right-part {
				flex-flow: column-reverse;
				align-items: flex-end;
				.user-actions {
					margin-bottom: 10px;
					margin-left: 0;
				}
			}
		}

		@include phone {
			flex-wrap: wrap-reverse;

			.count-wrap {
				width: 100%;
				max-width: none;
				margin-top: 30px;
				justify-content: center;
				display: none;

				span {
					font-size: 14px;
					margin-left: 8px;
				}
			}

			.right-part {
				width: 100%;
			}
		}
	}
}

.ant-dropdown-menu {
    box-shadow: $box-shadow;
	margin-top: 6px;
	border-radius: $radius-ex-sm;
	padding: 2px 12px;

    &-item {
        font-size: 12px;
        font-weight: 700;
        color: $grey;
		padding: 0;
		transition: all 0.2s ease;
		
		a, div {
			padding: 8px 12px;
        	display: flex;
        	align-items: center;
			transition: all 0.2s ease;
		}

        &-disabled {
            cursor: default;
        }

		&:not(:last-of-type) {
			border-bottom: 1px solid rgba($lightgrey, 0.1);
		}

        @include icon {
            font-size: 14px;
            margin-right: 8px;
        }

        .ant-select {
            width: 100%;
            // font-weight: 500;
            font-size: 10px;
        }

        &:hover {
            background: $white;
            color: $sc;
        }
    }
}

.login-modal {
	.forgot-pwd-btn {
		margin-left: auto;
		font-size: 12px;
		color: $sc;
		height: 34px;
		margin-top: -12px;
		padding: 0 16px;
		border-radius: $radius-ex-sm;
	
		&:hover, &:focus, &:active {
			color: $sc;
			background-color: rgba($lightgrey, 0.1);
		}
	}
} 