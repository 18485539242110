.logo {
    max-width: 120px;
	position: relative;

	span {
		font-size: 18px;
		font-weight: 700;
		display: block;

		&:nth-child(1) { color: $mc; }
		&:nth-child(2) { color: $sc; }
		
		&.bg-dash {
			color: $lightblue;
			font-size: 42px;
			font-weight: 900;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			transition: all 0.2s ease;
		}
	}

	cursor: default;
	&.clickable {
		cursor: pointer;

		&:hover {
			.bg-dash { left: 50%; }
		}
	}
}
