.map-container {
	height: 100%;

	canvas {
		border-radius: $radius;
	}

	.mapboxgl-control-container {
		display: none;
	}

	.ant-spin {
		display: block;
		opacity: 0;
		z-index: 1;
		transition: opacity 0.2s ease;

		position: absolute;
		inset: 0;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: $radius;
		pointer-events: none;

		&-spinning {
			opacity: 1;
		}
	}
}
