.ant-carousel {
	background-color: rgba($lightgrey, 0.1);
	border-radius: $radius;
	padding: 12px 50px;

	.slick-dots {
		li button {
			background: $sc;
			width: 8px;
			height: 8px;
			border-radius: 50%;
		}

		li.slick-active {
			width: 16px;

			button {
				background: $sc;
			}
		}
	}

	.slick-dots-bottom {
		bottom: -18px;
	}

	.slick-initialized .slick-slide {
		padding: 4px; 

		> div {
			display: flex;
			align-items: center;
			height: 95px;
		}
	}

	.slick-prev,
	.slick-next,
	.slick-prev:hover,
	.slick-next:hover {
		width: unset;
		height: 100%;
		color: $grey;
		font-size: 20px;
		margin-top: 0;
		transform: none;
		top: 0;

		display: inline-flex !important;
  		align-items: center;

		&::before {
			content: none;
		}
	}

	.slick-next {
		right: -35px;
	}

	.slick-prev {
		left: -35px;
	}
}
