.testimonies-page {	
	.bloc {
		box-shadow: $box-shadow;
		border-radius: $radius;
		padding: 20px 30px 30px;
		margin-bottom: 30px;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.testimonies-wrap {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		margin-bottom: 60px;
		margin-top: 30px;

		.bloc {
			width: calc(50% - 15px);

			@include mobile {
				width: 100%;
			}

			.text {
				font-style: italic;
				
				@include icon {
					font-size: 2em;
					padding: 10px;
					width: unset;
					height: unset;

					&:nth-child(1) {
						transform: scale(-1);
						vertical-align: bottom;
					}

					&:nth-child(3) {
						vertical-align: top;
					}
				}

				span:nth-child(2) {
					line-height: 1.6em;
				}
			}

			.infos {
				margin-top: 20px;
				width: 200px;

				h4 {
					font-weight: 600;
					color: $sc;
				}

				p {
					margin-top: 8px;
					font-size: .8em;
					color: $lightgrey;

					&:last-of-type {
						font-weight: 700;
						color: $grey;
					}
				}
			}
		}
	}
}
