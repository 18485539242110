.page-footer {
	.upper-footer {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		margin-top: auto;
		padding-top: 60px;
	
		.images {
			display: flex;
			align-items: center;
	
			img {
				&:first-child {
					width: 100px;
					margin-right: 8px;
					margin-top: 2px;
				}
	
				&:last-child {
					width: 110px;
				}
			}
		}
	
		small {
			font-size: 11px;
			line-height: 1.4;
			max-width: 50%;
			margin-left: 20px;
		}
	
		.links {
			margin-left: auto;
			display: flex;
			padding-left: 20px;
	
			a {
				color: $grey;
				font-size: 12px;
				margin-left: 30px;
	
				&:first-child {
					margin-left: 0;
				}
			}
		}
	
		@media only screen and (max-width: 1200px) {
			flex-flow: row wrap;
	
			small {
				max-width: 70%;
				margin-left: 0;
			}
	
			.images {
				width: 100%;
				margin-bottom: 10px;
			}
		}
	
		@include phone {
			small {
				max-width: 100%;
			}
	
			.links {
				width: 100%;
				margin-top: 20px;
				padding-left: 0;
			}
		}
	}

	.lower-footer {
		display: flex;
		justify-content: space-between;
		font-size: 11px;
		// padding-left: 20px;
		margin-top: 12px;
		color: $lightgrey;

		a {
			color: rgba(#454ade, 0.6);
		}
	}
}

// .status-modal {
//     max-width: 400px;

//     .ant-modal-close {
//         display: none !important;
//     }

//     p {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;

//         font-size: 14px;

//         margin: 0;
//         padding: 10px;
//         border-bottom: 1px solid rgba($lightgrey, 0.1);

//         &:last-of-type {
//             border-bottom: none;
//         }

//         label {
//             font-weight: 500;
//             color: $lightgrey;
//         }

//         > span {
//             @include icon {
//                 font-size: 8px;
//                 margin-right: 6px;
//             }
//         }
//     }

// 	small {
// 		display: block;
// 		text-align: right;
// 		padding: 10px;
// 		margin-top: 20px;
// 		color: $lightgrey;

// 		b {
// 			margin-left: 4px;
// 			color: $grey;
// 		}
// 	}

//     .green, .green * {
//         color: $green;
//     }

//     .red, .red * {
//         color: $red;
//     }
// }
