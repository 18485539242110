.about-page {
	flex-grow: 1;

	h3 {
		color: $grey;
		margin-top: 40px;
		margin-bottom: 16px;
	}

	.about-wrap {
		max-width: 750px;

		ul {
			list-style: disc;
			margin-left: 30px;

			li {
				margin-bottom: 16px;
				line-height: 1.4;
			}
		}
	}
}
