.user-filters {
    display: flex;
    gap: 20px;

    .ant-btn {
        height: 32px;
		border-radius: 10px;
		font-size: 12px;
		padding: 0 10px 0 16px;

		@include icon {
			font-size: 14px;
		}
    }

	.ant-badge-count {
		background: $mc;
		font-weight: 700;
		min-width: 18px;
		height: 18px;
		line-height: 18px;
		padding: 0 4px;
		font-size: 11px;
	}
}

.form-wrap.date,
.form-wrap.dynamic {
    width: 340px;

    display: flex;
    flex-flow: column nowrap;
    gap: 8px;

    position: relative;
    background-color: $white;
    box-shadow: $box-shadow;
    margin-top: 6px;
    border-radius: $radius;
    padding: 12px;

    &.date {
        width: 340px;
    }

    .ctn {
        margin: 12px 6px 0 6px;

		&.quick-actions {
			display: flex;
			flex-flow: row wrap;
			column-gap: 6px;
		}

        label {
            font-size: 12px;
        }

        .ant-input,
        .ant-select {
            display: block;
            font-size: 14px;
        }

        .ant-picker-input > input {
            text-align: center;
        }

		.ant-picker {
			width: 100%;
		}

        .ant-btn {
            height: 30px;
			border-radius: 10px;
			font-size: 12px;
			margin-bottom: 6px;
        }
    }
}

.filters-buttons {
    width: 100%;
    display: flex;
	flex-flow: row wrap;
    justify-content: flex-end;
	column-gap: 6px;
	margin-top: 20px;

    .ant-btn {
		margin-bottom: 6px;
    }
}
