body, input, button, textarea {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $font;
    font-weight: 500;
    color: $tc;
    line-height: 1.2;
}

p {
	line-height: 1.4;
}

h1 {
	font-size: 42px;
	font-weight: 700;
	color: $black;

	@include phone {
		font-size: 34px;
	}
}

.page-header {
	.subtitle {
		font-size: 16px;
		font-weight: 300;
		color: $lightgrey;
		margin-bottom: 4px;
		display: flex;
		align-items: center;

		.ant-btn {
			height: 26px;
			padding: 0 12px;
			font-size: 12px;
			color: $lightgrey;
			background: rgba($lightgrey, 0.15);
			border-radius: 8px;
			margin-right: 12px;
			transform: translateY(1px);

			&:hover, &:focus, &:active {
				color: $lightgrey;
			}

			@include icon {
				font-size: 12px;
				background: rgba($lightgrey, 0.05);
			}
		}
	}
}

h2 {
	font-size: 28px;
	color: $grey;
	font-weight: 700;
}

h3 {
	font-size: 22px;
	font-weight: 700;
}

a {
	font-weight: 700;
	transition: all 0.2s ease;

	&:hover {
		opacity: 0.8;
	}
}