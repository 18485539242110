.invervention-page {

	.page-header {
		margin-bottom: 40px;
	}

	.past-interventions-btn  {
		float: right;
		margin-top: -70px - 18px;
	}

	.ant-spin-blur {
		overflow: visible;
	}

	.steps-wrap {
		margin-top: 60px;

		.ant-steps {
			.ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon,
			.ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
				color: $sc;
			}

			&.disabled {
				.ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']{
					cursor: default;
				}

				.ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon,
				.ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
					color: $grey;
				}
			}
		}			

		.ant-steps-item {
			&-icon {
				margin-left: 37px;
				margin-top: -27px;
				height: auto;
			}

			&-title {
				font-size: 14px;
				font-weight: 700;
			}
			
			.ant-steps-icon {
				display: block;
				width: 62px;
				height: 62px;
				line-height: 54px;
				border: 4px solid $white;
				background-color: lighten($grey, 50);
				border-radius: 50%;
				color: $grey;

				@include icon {
					font-size: 26px;
					transform: translateY(4px);
				}
			}

			&-process {
				.ant-steps-item-title,
				.ant-steps-icon {
					color: $sc !important;
				}
	
				.ant-steps-icon {
					background: lighten($sc, 56);
				}

				.ant-steps-item-container .ant-steps-item-tail:after {
					background: linear-gradient(90deg,rgba($sc, 0.1) 0%, rgba($lightgrey, 0.1) 100%);
				}
			}
	
			&-finish {
				.ant-steps-item-title,
				.ant-steps-icon {
					color: $green !important;
				}
	
				.ant-steps-icon {
					background: lighten($green, 30);
				}

				.ant-steps-item-container .ant-steps-item-tail:after {
					background: linear-gradient(90deg,rgba($green, 0.1) 0%, rgba($sc, 0.1) 100%);
				}
			}
		}
	}

	.form-wrap {
		.form-step {
			max-width: 740px;
			margin: 60px auto;
		}

		h2 {
			margin-bottom: 16px;
		}

		h3 {
			font-size: 18px;
			color: $lightgrey;
			margin-bottom: 8px;
			
			&:after { content : ' :'}
		}
	}

	.intervention-step-1 {
		.inputs-wrap-1,
		.inputs-wrap-2 {
			display: flex;
			justify-content: space-between;

			> * {
				width: calc(50% - 10px);
			}
		}
	}

	.intervention-step-2,
	.intervention-step-3,
	.intervention-step-4 {
		.inputs-wrap {
			display: flex;

			.ant-row {
				flex-direction: column-reverse;
				align-items: center;
				margin-right: 20px;
			}

			label {
				color: $grey;
				text-align: center;
				display: inline-block;
				width: 100%;
				font-size: 11px;
				margin-top: 6px;
			}

			.ant-input-number {
				border: none;
				border-radius: $radius-sm;
				width: 110px;
				
				input {
					text-align: center;
					color: $sc;
					font-size: 22px;
					padding-right: 28px;
					height: 48px;
				}

				&-handler {
					&-down {
						border: none;
					}

					&-wrap {
						opacity: 1;
						background-color: transparent;
						border-left: none !important;
						right: 4px;
						width: 14px;
					}

					&-up-inner, &-down-inner {
						width: 7px;
					}
				}
			}
		}
	}

	.intervention-step-4 {
		display: flex;
		justify-content: space-between;

		> * {
			width: calc(50% - 10px);
		}

		.inputs-wrap {
			flex-direction: column;

			.ant-row {
				flex-direction: column;
				align-items: flex-start;
			}

			label {
				text-align: start;
				margin-left: 8px;
			}
		}
	}

	.form-step-4 {
		max-width: 100% !important;

		h2 {
			max-width: 740px;
			margin-left: auto;
			margin-right: auto;
		}

		.ant-btn.main {
			background: rgba($sc, 0.1);
			// border: 2px solid $sc;
			color: $sc;
		}
	}

	.buttons {
		flex-flow: row wrap-reverse;

		> div {
			display: flex;
		}
		
		.extra-btns {
			width: 100%;
			margin-right: 0;
			margin-top: 18px;
			flex-flow: row-reverse;

			.ant-btn {
				margin-right: 0;
				height: 36px;
				font-size: 13px;
				border-radius: $radius-ex-sm;

				&:last-child {
					margin-right: 10px;
				}
			}
		}
	}

	@media only screen and (max-width: 1200px) {
        .past-interventions-btn {
			margin-top: -20px;
			margin-bottom: 60px;
		}
    }

	@include mobile {
		.steps-wrap {
			.ant-steps-item {
				&-icon {
					margin-left: 42px;
					margin-top: -27px;
				}

				.ant-steps-icon {
					width: 50px;
					height: 50px;
					line-height: 36px;
	
					@include icon {
						font-size: 20px;
						// trans
					}
				}

				&-title {
					font-size: 12px;
				}

				&-tail {
					top: -4px;
					margin: 0 0 0 50px;
				}
			}
		}
	}

	@media only screen and (max-width: 740px) {
		.ant-steps-item-icon {
			margin-left: 0 !important;
		}

		.ant-steps-item-content {
			display: none;
		}

		.ant-steps-item-tail {
			margin: 0 0 0 10px !important;
		}
		
		.buttons {
			margin: 40px -18px 0;

			.main-btns {
				flex-flow: row wrap;
				justify-content: center;
				width: 100%;

				.ant-btn {
					margin-top: 10px;
					margin-right: 0;
					width: 100%;
					justify-content: center;

					i {
						max-width: 80%;
    					white-space: break-spaces;
    					line-height: 1.4;
					}
				}
			}

			.extra-btns {
				justify-content: center;
			}
		}

		.intervention-step-1 {
			.inputs-wrap-1,
			.inputs-wrap-2 {
				flex-flow: column;	

				> * {
					width: calc(100%);
				}
			}
		}
	}

	@media only screen and (max-width: 620px) {
		.intervention-step-2,
		.intervention-step-3,
		.intervention-step-4 {
			.inputs-wrap {
				flex-flow: column;
				.ant-row {
					align-items: flex-start;
				}
			}
		}
	}
}

.past-interventions-modal {
	h3 {
		margin-bottom: 6px;
	}

	p {
		font-size: 14px;
		color: $lightgrey;
		margin-bottom: 32px;
	}

	.error {
		margin-bottom: 2px;
		font-size: 16px;
		font-weight: 700;
		color: $sc;

		@include icon {
			font-size: 16px;
			margin-right: 12px;
			transform: translateY(2px);
		}
	}

	li:not(:last-of-type) {
		border-bottom: 1px solid rgba($lightgrey, 0.1);
		margin-bottom: 16px;
		padding-bottom: 16px;
	}

	li a {
		display: flex;
		align-items: flex-start;

		.ant-btn {
			margin-right: 24px;
			border-radius: 10px;
			height: 40px;
			padding: 0 16px;

			@include icon {
				font-size: 20px;
				margin: 0 !important;
			}
		}

		> span {
			display: flex;
			flex-flow: column;
			line-height: 1.4;
			color: $sc;

			b {
				font-size: 16px;
			}

			small {
				color: $lightgrey;
				font-size: 12px;
				font-weight: 500;
			}
		}
	}
}