.map-page {
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	.map-wrap {
		flex-grow: 1;
		height: 100%;
		min-height: 500px;
		position: relative;

		.map {
			width: 100%;
			height: 100%;
			position: absolute;
		}
	}
}
