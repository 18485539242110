body {
    width: 100%;
    min-height: 100vh;
	background: #FCFBFC;
}

.page-wrap {
	position: relative;
	max-width: 1600px;
	min-height: 100%;
	margin: auto;
	padding: 16px 16px 16px 0;

	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: flex-start;

	@include mobile {
		flex-flow: column;
		padding: 16px 0;
	}
}

.page-nav {
	width: 280px;
	padding: 20px 26px 10px 22px;

	@include mobile {
		width: 100% !important;
		padding: 8px 28px 6px !important;
	}
}

.page-content {
    background: $white;
	width: calc(100% - 280px);
    min-height: calc(100vh - 32px);
    padding: 20px 50px 20px;
    box-shadow: $box-shadow-big-item;
	border-radius: $radius-lg;

	display: flex;
	flex-flow: column nowrap;

    position: relative;
    z-index: 2;

	@include mobile {
		width: 100% !important;
		padding: 20px 28px !important;
	}
}

.bloc-content {
    margin-bottom: 100px;
}

.error-display {
    h1 {
		color: $red;
	}
}

[data-collapsed-nav="true"] {
	.page-nav {
		width: 84px;
		padding: 20px 22px 10px 22px;
	}

	.page-content {
		width: calc(100% - 84px);
	}
}

.hidden { display: none !important; }

@include not-mobile {
    .mobile-only { display: none !important; }
}

@include mobile {
    .desktop-only { display: none !important; }
}

.ant-modal {
	&-content {
		border-radius: $radius;
	}

	h3 {
		color: $grey;
		margin-top: 12px;
		margin-bottom: 24px;
	}
}

.ant-popover {
	&-arrow { 
		display: none !important;
	}

	&-inner {
		border-radius: $radius-sm;
	}

	&-placement-bottom, &-placement-bottomLeft, &-placement-bottomRight {
		padding-top: 2px;
	}

	&.ant-popconfirm {
		.ant-popover-message-title {
			font-weight: 500;
			font-size: 12px;
			max-width: 260px;
		}

		.ant-popover-buttons {
			display: flex;
			justify-content: flex-end;
	
			button.ant-btn {
				height: 32px;
				font-size: 12px;
				border-radius: $radius-ex-sm;
				padding: 0 16px;
			}
		}
	}
}

.ant-tooltip {
	max-width: 600px;
	font-size: small;
	
	b {
		font-weight: bolder;
	}

	&-arrow { 
		display: none !important;
	}

	&-inner {
		border-radius: $radius-sm;
		padding: 8px 12px;
	}
}

.ant-message {
	&-warning {
		display: flex;
		padding-top: 4px;

		> :last-child {
			display: flex;
			flex-flow: column;
			text-align: left;
			margin-top: -2px;

			b {
				margin-bottom: 6px;
				font-weight: 700;
			}

			ul {
				background: rgba($lightgrey, 0.1);
				color: $grey;
				font-size: 12px;
				border-radius: 6px;
				padding: 12px 18px 12px 30px;
				margin: 4px 0;

				li {
					list-style-type: disc;
				}
			}
		}
	}
}