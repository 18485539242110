.page-nav {
	.logo-wrap {
		display: flex;
		align-items: center;
		
		.ant-btn {
			height: 24px;
			background: $white;
			color: $lightgrey;
			box-shadow: 0 2px 6px -1px rgba($black, 0.1);
			border-radius: 6px;
			margin-right: 20px;
			padding: 0 2px 0 8px;
			transition: padding 0s ease;

			i {
				display: none;
				font-size: 12px;
				margin-left: 8px;
			}

			@include icon {
				margin: 0;

				&:first-of-type { 
					display: none;
					transform: translateX(2px);
				}
				&:last-of-type {
					display: block;
					transform: translateX(-2px);
				}

				&:not(:nth-child(2)) {
					font-size: 10px;
				}
			}
		}
	}

	.links {
		margin-top: 40px;

		p {
			font-size: 12px;
			color: $lightgrey;
			padding: 4px 12px;
		}

		a {
			display: flex;
			align-items: center;
			padding: 12px;
			font-size: 14px;
			font-weight: 700;
			border-radius: $radius-ex-sm;
			color: $grey;
			margin: 2px 0;

			&:hover {
				opacity: 1;
				background-color: rgba($lightgrey, 0.1);
			}

			@include icon {
				font-size: 16px;
				margin-right: 16px;
			}

			&.active {
				color: $mc;
				background-color: rgba($mc, 0.1);
			}
		}
	}

	.ant-divider {
		margin: 30px 0;
	}

	@include mobile {
		.logo-wrap {
			flex-flow: row-reverse;
			justify-content: space-between;

			.ant-btn {
				padding: 0 10px !important;
				margin-right: 0;
				height: 32px;

				i { display: block; }

				span:first-of-type,
				span:last-of-type {
					display: none !important;
				}
			}
		}

		.links {
			display: flex;
			flex-flow: row wrap;
			max-height: 10000px;
			overflow: hidden;
			margin-top: 30px;
			margin-bottom: 20px;

			> div {
				width: calc(50% - 10px);

				&:first-child {
					margin-right: 20px;
				}
			}

			.ant-divider {
				display: none;
			}
		}

		&.small {
			.links {
				max-height: 0;
				margin-top: 0;
			}
		}
	}

	@include phone {
		.links > div {
			width: 100%;

			&:first-child {
				margin-bottom: 20px;
				margin-right: 0;
			}
		}
	}

	@include not-mobile {
		&.small {
			.logo-wrap {
				flex-flow: column-reverse;
	
				.logo {
					text-align: center;
					margin-bottom: 20px;
					
					span {
						font-size: 12px;
					}
	
					.bg-dash {
						transform: translateX(-50%);
						left: 50% !important;
						font-size: 28px;
					}
				}
	
				.ant-btn {
					padding: 0 8px 0 2px;
					margin-right: 0;
					span:first-of-type { display: block; }
					span:last-of-type { display: none; }
				}
			}
	
			.links {
				margin-top: 34px;
	
				a i, p {
					display: none;
				}

				@include icon {
					margin-right: 0;
				}
			}
		}
	}
}