.home-page {
    .bloc {
        box-shadow: $box-shadow;
        border-radius: $radius;
        padding: 30px;
        display: flex;
        flex-flow: column;

        h3>a,
        h3>span {
            font-size: 16px;
            font-weight: 700;
            color: $grey;

            display: flex;
            align-items: center;
            margin-bottom: 12px;

            @include icon {
                font-size: 18px;
                margin-right: 12px;

                &:last-child {
                    margin-left: auto;
                    margin-right: 0;
                }
            }
        }

        p {
            color: $lightgrey;
            font-size: 13px;
        }

        .content-wrap {
            margin-top: auto;
            padding-top: 22px;
        }

        .data-blocs {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: flex-end;

            >span {
                padding: 8px 6px;
                width: 100%;
                display: flex;
                align-items: center;
                flex-flow: column;
                min-width: 80px;

                span {
                    font-size: 26px;
                    font-weight: 700;
                }

                small {
                    font-size: 12px;
                    font-weight: 500;
                    text-align: center;
                }

                &.of-2 {
                    width: 50%;

                    b {
                        font-size: 24px;
                    }
                }

                &.of-3 {
                    width: 33.33333%;

                    b {
                        font-size: 22px;
                    }
                }
            }
        }

        &.is-custom {
            background-color: rgba($sc, 0.1);
            box-shadow: $box-shadow-lighter;

            h3 * {
                color: $sc;
            }

            .custom-content-wrap * {
                color: $black;
                opacity: 0.4;
                font-size: 12px;
            }
        }

        &.no-bg {
            background-color: none;
            box-shadow: none;
        }
    }

    .intro-wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 60px;

        .intro-content {
            width: calc(66.66666% - 15px);

            b {
                font-weight: 700;
            }

            .ant-btn {
                margin-top: 30px;
            }
        }

        .bloc {
            width: calc(33.33333% - 15px);
            margin-top: -75px;
        }
    }

    .main-content-wrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;

        .map-wrap {
            width: calc(33.33333% - 30px);
            background-color: rgba($lightgrey, 0.04);
            box-shadow: $box-shadow-lighter;
            border-radius: $radius;
            position: relative;

            .ant-spin {
                background: rgba($sc, 0.1);
                background: linear-gradient(190deg, rgba($sc, 0.1) 0%, rgba($mc, 0.1) 100%);
            }

            .map {
                height: 100%;
            }
        }

        .blocs-wrap {
            width: calc(66.66666% - 0px);
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;

            .bloc {
                width: calc(50% - 15px);

                &:nth-child(1),
                &:nth-child(2) {
                    margin-bottom: 30px;
                }

                &:nth-child(1),
                &:nth-child(4) {

                    h3 a,
                    .data-blocs {
                        color: $mc;
                    }
                }

                &:nth-child(2),
                &:nth-child(3) {

                    h3 a,
                    .data-blocs {
                        color: $sc;
                    }
                }
            }
        }
    }

    .carousels-wrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .bloc {
            width: calc(50% - 15px);
            padding: 30px;

            .content-wrap {
                margin-left: -30px;
                margin-right: -30px;
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        .intro-wrap {
            flex-flow: row wrap;
            margin-bottom: 30px;

            .intro-content,
            .bloc {
                width: 100%;
                max-width: 600px;
            }

            .bloc {
                margin-top: 60px;
            }
        }

        .main-content-wrap {
            flex-flow: row wrap;

            .map-wrap {
                width: 100%;
                min-height: 200px;
                margin-bottom: 30px;
            }

            .blocs-wrap {
                width: 100%;
            }
        }

        .carousels-wrap {
            flex-flow: row wrap;

            .bloc {
                width: 100%;
            }
        }
    }

    @include mobile {
        .intro-wrap {
            .intro-content {
                width: 100%;
            }

            .ant-btn {
                height: 52px;

                >span {
                    line-height: 1.2;
                    white-space: break-spaces;
                    text-align: left;
                }
            }

            .bloc {
                width: 100%;
            }
        }
    }

    @include phone {
        .main-content-wrap .blocs-wrap .bloc {
            width: 100%;
            margin-bottom: 30px;

            &:nth-child(3) {

                h3 a,
                .data-blocs {
                    color: $mc;
                }
            }

            &:nth-child(4) {

                h3 a,
                .data-blocs {
                    color: $sc;
                }
            }
        }
    }
}

.user-info-modal {
    .ant-modal-confirm {
        &-body>.anticon {
            color: $sc;
            font-size: 18px;
            margin-top: 4px;
        }

        &-title {
            font-size: 18px;
            font-weight: 700;
        }

        &-content {
            color: $lightgrey;
            margin-top: 12px;
            margin-bottom: 12px;
            line-height: 1.4;
        }

        &-btns {
            display: none;
        }
    }
}