.stats-wrap .ant-spin-container {
	display: flex;
	flex-flow: row wrap;
	gap: 60px 30px;
	padding: 20px 0;

	.bloc-wrap {
		width: calc(50% - 15px);
		@media only screen and (max-width: 1200px) { width: 100%; }
		@media only screen and (max-width: 1000px) { width: calc(50% - 15px); }
		@media only screen and (max-width: 800px) { width: 100%; }

		h3 {
			font-size: 20px;
			color: $grey;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 20px;
			margin-bottom: 28px;
			
			i {
				position: relative;
				&:after {
					content: '';
					display: block;
					width: 20px;
					height: 3px;
					background: $grey;
					position: absolute;
					bottom: -12px;
				}
			}

			@include icon {
				font-size: 18px;
				margin-left: 18px;
				cursor: help;
			}
		}
	}
}

$colors: (
	$lightblue,
	$purple,
	$green,
	$red
); // TODO : apply colors to graphs

.bloc-content {
    position: relative;
	margin-bottom: 0;

	background: rgba($lightgrey, 0.1);
	border-radius: $radius;
	padding: 20px 10px;

	.counter {
		font-size: 36px;
		font-weight: 700;
		text-align: center;
		margin: 24px 0;
	}

    .rv-discrete-color-legend {
        position: absolute;
        top: 50%;
        right: 20px;
        text-align: left;
        transform: translateY(-50%);
		background: $white;
		box-shadow: $box-shadow-lighter;
		padding: 12px 20px;
		border-radius: $radius-ex-sm;
		z-index: 2;

		&-item {
			padding: 4px 10px;
			cursor: default;
		
			display: flex;
			align-items: center;
		
			&__color {
				margin-right: 12px;
				height: 3px !important;
				path { stroke-width: 3px; }
			}
		
			&__title {
				font-size: 14px;
				color: $grey;

				em {
					display: inline-block;
					opacity: 0.4;
					margin: 0 6px;
				}
			
				b {
					opacity: 1;
					font-weight: 700;
				}
			}

			&.disabled {
				opacity: 0.4;
				path { stroke: $lightgrey !important; }
			}
		}
    }

	.rv-xy-plot__axis__tick__text {
		font-size: 10px;
		font-weight: 700;
		fill: $grey;
		transition: all 0.1s;
	}

	.rv-xy-plot__series--label.vertical {
		.rv-xy-plot__series--label-text {
			text-anchor: start;
			font-size: 12px;
		}
	}

	.rv-xy-plot__series--label-text {
		cursor: pointer;
		font-size: 14px;
		font-weight: 700;
		text-anchor: middle;

		&[dominant-baseline="middle"] {
			fill: $white;
		}

		&[dominant-baseline="text-before-edge"] {
			transform: translateY(10px);
			fill: $white;
		}

		&[dominant-baseline="text-after-edge"] {
			transform: translateY(-4px);
			fill: $grey;
		}
	}
	
	.x-axis-label, .y-axis-label {
		font-weight: 700;
		opacity: 0.8;
	}
	
	.rv-xy-plot__axis__line {
		stroke: rgba($lightgrey, 0.4);
	}
	
	.rv-xy-plot__grid-lines__line {
		stroke: lighten($lightgrey, 10);
	}
}
