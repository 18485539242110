// DATATABLE
$font-family: $font;
$font-weight: 500;
$secondary-font-weight: 700;

$primary-color: $sc;
$border-color: rgba($black, 0.08);
$scroll-spacer-border: 1px solid $lightgrey !important;
$cell-horizontal-border: none;
$icon-color: $sc;

$header-height: 36px;
$header-background-color: rgba($sc, 0.04);
$header-cell-moving-background-color: rgba($sc, 0.08);
$header-foreground-color: $white;

@import '~ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham';

.sds-table {
    // Setup
    .ag-root-wrapper {
        padding: 8px 15px 0;
        margin: 0 -15px;
        border: none;
        background: none;
        // min-height: 220px;
    }

    .ag-root {
        border: none;
        box-shadow: $box-shadow-lighter;
		border-radius: $radius;
    }

    .ag-root-wrapper-body {
        min-height: 140px;
    }

    .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
        padding-top: 60px;
    }

    .ag-body-viewport.ag-layout-auto-height .ag-center-cols-clipper, .ag-body-viewport.ag-layout-auto-height .ag-center-cols-container {
        min-height: 60px;
    }

    // Header
    .ag-header {
        // border: 1px solid $mc;
        background: rgba($header-background-color, 0.0);

        > *:first-child {
            margin-left: -1px;
        }
    }

    .ag-header-icon .ag-icon {
        color: $sc;
    }

    .ag-header-cell::after, .ag-header-group-cell::after {
        border-color: $sc;
        margin-top: 0;
        height: 100%;
        opacity: 0.2;
		top: 0;
    }

    .ag-header-cell-label .ag-header-cell-text {
        margin-right: auto;
		color: $sc;
		margin-top: 4px;
    }

    .ag-header-cell-filtered {
        background: rgba($sc, 0.05);
    }

    .ag-floating-filter-button {
        display: none;
    }

	.ag-header-row-floating-filter {
		// height: 32px !important;
	}

    // Filters
    .ag-header-row {
		background-color: none;
	}

	.ag-header-viewport {
        background: $header-background-color;
    }

    .ag-header-row:last-of-type {
        background: $white;
    }

    // Input to look like Antd
	.ag-floating-filter-body {
		height: 100%;
	}

    .ag-floating-filter-input,
    .ag-filter-filter {
        height: 100%;
        padding: 4px 0;
        border: none !important;

        font-size: 10px;
        font-weight: 500;
        color: $tc;
        transition: all 0.3s ease;

		* { height: 100%; }
    }

	.ag-input-field-input {
		font-size: 10px;
		font-weight: 500;
		color: $grey;

		border: none !important;
		background-color: rgba($grey, 0.1) !important;
		border-radius: 8px;
		padding: 0 8px !important;
	}

    // Antd inputs
    .ag-floating-filter-full-body .ag-react-container,
    .ag-floating-filter-body .ag-react-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        > * {
            width: 100%;
			height: calc(100% - 8px);
			border-radius: 8px;
        }

        .ant-slider {
            margin-top: 0;
            margin-bottom: 0;
			height: unset;
        }

        .ant-select {
            // height: 20px;
			min-width: 120px;
            * { font-size: 10px; }

			&-open,
			&-focused {
				.ant-select-selection-item-content small {
					max-width: 0;
					margin-left: 0;
				}
			}

			&-selector {
				padding: 0 16px 0 6px !important;
				height: 100% !important;
				border-radius: 8px;
			}

            &-selection {
                height: inherit;
                min-height: auto;
                padding: 0;
                width: 100%;
                max-width: 100%;

				&-item {
					height: 21px !important;
					line-height: 20px !important;

					&-content {
						small {
							transition: all 0.2s ease;
							display: inline-block;
							max-width: 100px;
							font-weight: 500;
							margin-left: 3px;
							white-space: nowrap;
						}
					}
				}

                &__rendered {
                    height: 18px;
                    max-width: calc(100% - 30px);
                    margin: 0 0 0 2px;

                    ul {
                        width: 100%;
                        max-width: 100%;
                        height: inherit;
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;
                        position: relative;

                        li {
                            margin-top: 0;
                            margin-right: 4px;
                        }
                    }
                }

                .ant-select-search {
                    position: absolute;
                    left: 26px;
                    right: 0;
                }

                &__choice {
                    background: rgba($sc, 0.1);
                    border-color: transparent;
                    color: $mc;
                    padding: 0;
                    min-width: 22px;
                    text-align: center;
                }

                &__clear,
                .ant-select-arrow {
                    top: 11px;
                }
            }
        }

        .ant-picker {
            * { font-size: 10px; }

            .ant-calendar-picker-input {
                height: 20px;
                transform: translateY(-1px);
            }
        }

        .ant-checkbox-wrapper {
            font-size: 10px;
            font-weight: 700;
            color: $mc;

            .ant-checkbox-inner {
                width: 12px;
                height: 12px;

                &:after {
                    transform: translate(-50%, -50%) scale(0.75)
                }
            }

            .ant-checkbox {
                margin-top: 2px;

                + span {
                    background: rgba($white, 0.6);
                    padding: 2px 6px;
                    margin-left: 6px;
                    border-radius: 2px;
                }

                &-checked {
                    .ant-checkbox-inner:after {
                        transform: rotate(45deg) translate(-50%, -50%) scale(0.75);
                    }
                }
            }
        }
    }

    // Pinned Columns
    .ag-ltr .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right), .ag-ltr .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-last-left-pinned:not(.ag-cell-range-right), .ag-ltr .ag-root:not(.ag-has-focus) .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right) {
        border-right: 1px solid $sc !important;
    }

    .ag-pinned-left-header {
        border-color: $sc;
    }

    // Cells
    .ag-cell:focus,
    .ag-cell-focus {
        border-color: transparent !important;
        outline: none;
    }

    // Rows
    .ag-ltr .ag-cell {
        border-right: 1px solid rgba($lightgrey, 0.2) !important;
    }

    .ag-row {
        border-color: $border-color !important;
        cursor: pointer;
    }

    .ag-row.blink {
        animation: blink 1s infinite;
        &.red {
            animation: blinkRed 1s infinite;
        }
    }

    @keyframes blink {
		0%		{ background: transparent; }
		50%		{ background: rgba($mc, 0.2); }
		100%	{ background: transparent; }
	}

    @keyframes blinkRed {
		0%		{ background: rgba($red, 0.45); }
		50%		{ background: rgba($red, 0.65); }
		100%	{ background: rgba($red, 0.45); }
	}

    // Last Row
    .ag-row.ag-row-last {
        border-bottom: none;
    }

    // Pagination
    .ag-paging-panel {
        border-top: none;
        height: 38px;
		margin-top: 10px;
    }

	// Extra
	.ag-invisible-scrollbar {
		display: none;
	}

    // Filters popup
    .ag-filter {
        select {
            appearance: none;
            border: 1px solid rgba(215, 215, 215, 0.6);
            padding: 4px 10px;
            border-radius: 0;

            background: $white;
            color: rgba($grey, 0.6);

            font-size: 10px;

            background-position: right 50%;
            background-repeat: no-repeat;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
        }

        input[type="text"] {
            padding: 5px 10px !important;
            border: 1px solid rgba(215, 215, 215, 0.6) !important;

            font-weight: 500;
        }

        .ag-filter-condition {
            margin: 5px 0;
            display: flex;
            justify-content: center;

            label {
                margin: 0 5px;
            }
        }

        .ag-filter-apply-panel {
            padding: 0px !important;
            margin-top: 4px;

            button {
                background: none;
                border: none;
                padding: 5px 10px;
                width: 100%;

                color: $mc;
                font-size: 12px;
                font-weight: 700;

                cursor: pointer;
                transition: all 0.2s ease;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

	&[data-empty=true] {
		.table-menu {
			justify-content: center;
			margin-top: 40px;
		}

		button.main {
			font-size: 18px;
			height: 60px;
			padding: 0 42px;
			border-radius: 20px;
			box-shadow: 0 0 20px -2px rgba($sc, 0.1);

			@include icon {
				font-size: 22px;
			}
		}

		button.grey {
			display: none;
		}

		.ag-root-wrapper {
			display: none;
		}

		.table-menu.bottom {
			display: none;
		}
	}

    // PERSO
    .table-menu {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        margin-bottom: 10px;

        > *:not(:last-child) {
            margin-right: 10px;
        }

        @include mobile {
            flex-wrap: wrap-reverse;
        }

		.placeholder {
			width: 18rem;
			height: 2.5rem;
		}

        .ant-input-search {
            max-width: 300px;
            margin-right: auto;

            .ant-input {
                font-size: 12px;
                font-weight: 700;
            }

            @include mobile {
                width: 100% !important;
                max-width: none;
                margin-top: 12px;
            }
        }

        .ant-btn {
            font-size: 12px;
            padding: 0 16px;
			height: 38px;

            @include icon {
                font-size: 16px;
				margin: 0;
            }

            &.main {
                @include icon {
                    margin-right: 8px;
                }
            }
        }

        @at-root .table-popover-options {
            min-width: 200px;
			padding: 10px;

            .ctn {
                display: flex;
                flex-flow: column;
                align-items: flex-start;
            }

            .label-title {
                color: $lightgrey;
                font-size: 10px;
                font-weight: 700;
                margin-bottom: 2px;
            }

            hr {
                opacity: 0.2;
                margin-top: 20px;
                margin-bottom: 16px;
            }

            .columns-ctn {
                .ant-checkbox-group {
                    width: 100%;
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: space-between;
                }

                .ant-checkbox-group-item {
                    width: calc(50% - 8px);
                    margin: 4px 0 0;

                    font-size: 12px;
                    font-weight: 700;
                    color: $lightgrey;

                    display: flex;
                    align-items: center;

                    > span {
                        transition: all 0.3s;
                        line-height: 1.2;
                    }

					.ant-checkbox {
						top: 0;
					}

                    .ant-checkbox-checked + span {
                        color: $grey !important;
                    }

                    &:hover > span {
                        color: rgba($grey, 0.6);
                    }

                    .ant-checkbox-input:focus + .ant-checkbox-inner {
                        border-color: rgba(215, 215, 215, 0.6);
                    }
                }
            }

            .reset-ctn {
                .ant-btn-group {
                    width: 100%;
                    display: flex;
					margin-top: 4px;
                }

                .ant-btn {
                    padding: 0 16px !important;
                    height: 24px;
                    font-size: 12px;
                    border-radius: 8px !important;
                    margin: 0 4px !important;

                    &:first-of-type { margin-left: 0 !important; }
                    &:last-of-type { margin-right: 0 !important; }
                }
            }
        }

        &.bottom {
            margin-bottom: 0;
            margin-top: -32px;
            justify-content: flex-start;

            .ant-select {
                font-size: 12px;
                font-weight: 700;
            }

            label {
                margin-right: 6px;
                font-size: 12px;
                color: $lightgrey;
            }

            .ant-select {
                min-width: 55px;
            }

            .ctn {
                margin-left: 12px;
                display: flex;
                align-items: center;
            }
        }
    }

}

.table-modal {
    width: 520px !important;

    .ant-modal-close {
        display: block !important;
    }

    .ant-modal-body {
        padding: 24px;
    }

    .ant-modal-confirm-content {
        margin-top: 0;
    }

    .ant-modal-confirm-btns {
        display: none;
    }

    pre {
        font-size: 12px;
        background: rgba($lightgrey, 0.2);
        border-radius: 2px;
        padding: 10px;
        margin-top: 10px;
    }

    .tags {
        margin-bottom: 0;
        margin-top: -10px;

        * {
            font-size: 12px;
        }
    }
}

.table-popover {
	img {
		width: 150px;
		height: 150px;
		object-fit: contain;
	}
}